import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({ children, source }) => {
    return (
        <div className="banner">
            <div className="children">{children}</div>
            <img src={source} alt="bannerimage" />
        </div>
    );
};

Banner.propTypes = {
    children: PropTypes.node
};

export default Banner;
