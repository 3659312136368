import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Banner from 'components/Banner';

import index_banner from 'assets/images/pd_pattern_grey.png';

const IndexPage = () => {
  return (
    <Layout pageName="goodbye">
      <Helmet>
        <title>ciao</title>
      </Helmet>
      {/* <Banner source={index_banner}></Banner> */}
      <Container>
        <h1>Du hast dich erfolgreich abgemeldet</h1>
        <h3>In Zukunft bekommst du keine E-Mails aus dem pd_dev mehr.</h3>
      </Container>
    </Layout>
  );
};

export default IndexPage;
